.App {
  text-align: center;
}

body{
  background-image: url("../src/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1{
  text-align: center;
  color: #eae0c8;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #9f0502;
  height: 50px;
  
}

h2{
  text-align: center;
  color: #FAEEC8;
  font-size: 20px;
}

#contest-title {
  color: white;
  margin: auto;
  font-weight: bolder;
}

#title-box {
  width: 100%;
  height: 80px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  background-color: #c39e38;
  display: flex;
  justify-content: center;
}

#play_button {
  cursor: pointer;
}

.play-button {
  background-color: #c39e38;
  color: white;
  padding: 12px 20px;
  font-size: 24px;
  margin: auto;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 200px;
  margin-top: 20px;
}

.play-button:hover {
  background-color: darkgoldenrod;
}

.form-container {
  width: 100%;
  max-width: 740px;
  background-color: rgba(255, 255, 255, 0.90);
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20%;
}

label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.label-title {
  margin-left: 10px;
  margin-bottom: 4px;
}

input[type="text"], input[type="tel"], input[type="email"], input[type="date"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 100px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  background-color: #ffdfbf;
  opacity: 90%;
}

.checkbox-label {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px; 
  width: 100%;
  margin: 0 auto;
  color: black;
  border-radius: 10px;
}

.checkbox-label a {
  text-decoration: none;
  font-weight: 600;
}

input[type="checkbox"] {
  margin-top: 0; 
}

.form-container button {
  grid-column: 1 / span 2;
  background-color: #c39e38;
  color: white;
  padding: 12px 20px;
  font-size: 20px;
  margin: auto;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-container button:hover {
  background-color: darkgoldenrod;
}

.term-container {
  margin-bottom: 100px;   
  max-width: 740px;
  width: 100%;
  margin:  auto; 
  margin-top: 0px;
}

.acceptbutton {
  background-color: #c39e38;
  color: white;
  padding: 12px 20px;
  font-size: 24px;
  margin: auto;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 200px;
  margin-top: 20px;
}

.acceptbutton:hover {
  background-color: darkgoldenrod; 
}

/*
.gamebox {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 600px;
  margin: auto;
  margin-bottom: 120px;

*/
.gamebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Add any additional styling for the gamebox container */
}

.big{
  font-size: 25px;
}
/*
#spin-wheel {
  width: auto;
  height: 500px;
  margin: auto 0;
}*/

#spin-wheel {
  max-width: 50%; /* Ensures SVG does not exceed its container */
  height: auto; /* Maintains aspect ratio */
  /* Add any additional styling for the SVG */
}

.how-to-play-img {
  max-width: 30%; /* Ensures image does not exceed its container */
  height: 4%; /* Maintains aspect ratio */
  /* Add any additional styling for the image */
}
.error {
  color: red;
  margin-left: 4px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 800px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  background-color: #fff;
  padding: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.close:hover {
  background-color: #333;
  color: #fff;
}

#consent {
  margin: auto;
  margin-left: 4px;
}

footer {
  width: 100%;
  height: 80px;
  background-color: #c39e38;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  bottom: 0;
}

footer h1 {
  margin: auto;
  color: white;
  font-size: 20px;
}

header {
  display: flex;
  justify-content: space-evenly ;

}

.main-logo {
  width: 300px;
}

.header-image {
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-right: 300px;
}

.spin-image {
  width: 400px;
  padding-top: 100px;
  margin-left: 0px;
}

.spin-image {
  width: 100%;
  max-width: 400px; /* Adjust as needed */
}

.weekly-prizes {
  margin-top: 20px;
    text-align: center;
}

.main-content {
  display: flex;
  flex-direction: column;
    align-items: center;
  
  margin-bottom: 40px;
}

.play-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
}

.play-content a{
  display: flex;
  text-decoration: none;
}

.sponsor-container {
  width: 100%;
  height: auto;
  background-color: rgba(0, 102, 255, 0.5);
  padding-top: 10px;
  padding-bottom: 20px;
}

.mySponsors {
  display: flex;
  justify-content: center;
}

.mySponsors img {
  width: 20%;
  padding: 4px;
  cursor: pointer;
}

.mySponsors h1 {
  stroke: black;
}

.center-transform {
  transform-origin: center;
}

#how-to-play img{
  width: 400px;
  height: 500px;
}

#lose-page {
  background-color: #D6D5D3;
  width: auto;
  height: auto;
}

#lose-page h1 {
  margin: 0px;
  padding: 20px;
  color: #1B1464;
}

#lose-page p {
  margin: 0px;
  color: #000099;
  width: 80%;
  margin: auto;
  padding: 10px;
}

#win-page {
  background-image: url(./images/win-bg-2.png);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 900px;
  padding-top: 80px;
}

#win-page h1{
  color: #1B1464;
  margin-bottom: 40px;
}

#win-page h2{
  color: #663300;
}

#win-page h3{
  color: black;
  width: 80%;
  margin: auto;
  font-weight: 400;
}

#win-page p{
  color: black;
  width: 80%;
  margin: auto;
  font-weight: 800;
  text-align: center;
}

#win-content {
  padding-top: 100px;
}

.text-darkblue {
  color: darkblue;
}

.text-blue {
  color: #000099;
}

.text-red {
  color: #42210B;
  font-weight: 30;
}

#math-question form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 20px;
}

#question {
  display: flex;
  margin: auto;
}

#math-form button {
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: #c39e38;
  color: white;
  padding: 12px 20px;
  font-size: 24px;
  margin: auto;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 200px;
  margin-top: 20px;
}

#math-form button:hover {
  background-color: darkgoldenrod;
}

#math-form input {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 150px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: none;
  margin-left: 10px;
}

#coupons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  margin: auto;
}

.loseh1 {
  font-size: large;
  background-color: #c39e38;
  color: #42210B;
}

#coupons img {
  margin: 10px;
}

#coupon1 {
  border: 4px solid #c39e38;
}

#coupon2 {
  border: 4px solid #1B1464;
}

#lose-page button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  background-color: #c39e38;
  color: white;
  padding: 12px 20px;
  font-size: 24px;
  margin: auto;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 200px;
  margin-top: 20px;
}

#lose-page button:hover {
background-color: darkgoldenrod;
}

#lose-btn {
  text-decoration: none;
  padding: 10px;
}


